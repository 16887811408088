import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private redirectUrl;
  constructor(
    public authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.getJwtToken()) {
      request = this.addToken(request, this.authService.getJwtToken());
    }

    return next.handle(request)
      .pipe(
        catchError( (error) => {
          console.log(error)
          if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
                // console.error('Error Event');
            } else {
                // console.log(`error status : ${error.status} ${error.statusText}`);
                switch (error.status) {
                    case 0: //back-end 0ff
                    this.authService.logout();
                    this.router.navigate(['/login']);
                    setTimeout(() => {
                      this.toastr.clear();
                      this.toastr.error('Falha de comunicação', 'Servidor indisponível');
                    }, 100);
                    break;
                    case 401: // login
                    this.authService.logout();
                    this.router.navigate(['/login']);
                      // this.router.navigateByUrl(
                      //   this.router.createUrlTree(
                      //     ['/login'], {
                      //       queryParams: {
                      //         // TODO Redirecionar para URL Solicitada
                      //         // redirectUrl: 'TODO Redirecionar para URL Solicitada'
                      //       }
                      //     }
                      //   )
                      // );
                    setTimeout(() => {
                        this.toastr.clear();
                        this.toastr.warning('Você não está logado', 'Erro ao acessar');
                      }, 100);
                      // console.log('Erro Interceptor - 401', error.error);
                    break;
                    case 403:     // forbidden
                      // this.router.navigateByUrl("/unauthorized");
                      // console.log('Erro Interceptor - 403' + error.statusText);
                      break;
                }
          }
        } else {
            console.error('some thing else happened');
        }
          return throwError(error);
     })
      );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `INVOICES=${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.jwt);
          return next.handle(this.addToken(request, token.jwt));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
