export class ItemBikeOrdemPlanning {
    id: number;
    bikeModelID: string;
    orderPlanningID: number;
    quantity: number;
    bikeModelName?: string;
    observation?: string;
    includeInPurchase?: boolean;
    unitPrice?: number;
    activeTotalPrice?: number;
}
