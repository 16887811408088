import { Injectable } from '@angular/core';
import { config } from '@shared/config/config';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { UserAdminService } from 'src/app/home/user-admin/service/user-admin.service';
import { User } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  public fcmToken: any;
  currentMessage = new BehaviorSubject(null);

  currentStatus = new BehaviorSubject(false);
  userLogged: User = new User();
  currentToken: string = '';

  constructor(private http: HttpClient,
    private angularFireMessaging: AngularFireMessaging,
    private userAdminService: UserAdminService) {

    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );

    // if (Notification.permission === 'granted') {
    //   console.log('permission === granted');
    //   this.angularFireMessaging.getToken.pipe(
    //     map(token => { /*.... check if token already subscribed on chanel (back-end)....*/
    //       this.currentToken = token;
    //       console.log('token already exists', token);
    //     })
    //   );
    // }

    this.angularFireMessaging.tokenChanges.subscribe((newToken) => {
      if (Notification.permission === 'granted' && this.userLogged.id && this.userLogged.dashboardNotification) {
        this.currentToken = newToken;
        this.updateToken(newToken);
      }
    }, (err) => {

    });

    this.userAdminService.getUserLogged().subscribe(data => {
      this.userLogged = data;
      if (Notification.permission === 'granted') {
        if (this.userLogged.dashboardNotification) {
          this.requestPermission();
        } else {
          this.unsubscribe();
        }
      }
    });
  }

  /**
 * update token in firebase database
 *  
 * @param token token as a value
 */
  updateToken(token: string) {
    this.fcmToken = token;
    const obj = {
      "token": this.fcmToken
    }
    this.subscribeForNotifications(obj).subscribe((res) => {
      this.currentStatus.next(true);  
    }, (err) => {
      this.currentStatus.next(false);
      console.error('Unable to subscribe token in spring restfull service.', err);

    });
  }

  subscribeForNotifications(obj: any): Observable<any> {
    return this.http.post(`${config.apiUrl}/rest/fcm/subscribe/user_notification`, JSON.parse(JSON.stringify(obj)));
  }

  unsubscribeForNotifications(obj: any): Observable<any> {
    return this.http.post(`${config.apiUrl}/rest/fcm/unsubscribe/user_notification`, JSON.parse(JSON.stringify(obj)));
  }

  /**
  * request permission for notification from firebase cloud messaging
  * 
  */

  requestPermission() {
    return this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.currentToken = token;
        this.updateToken(token);
      },
      (err) => {
        this.currentStatus.next(false);
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  deleteToken() {
    this.angularFireMessaging.getToken
      .pipe(mergeMap(token => this.angularFireMessaging.deleteToken(token)))
      .subscribe(
        (token) => {
          this.currentStatus.next(false);
          this.currentToken = '';
          console.log('FCM token deleted!');
        },
        (err) => {
          console.error('Error deleting FCM token!');
        }
      );
  }

  unsubscribe() {
    this.fcmToken = this.currentToken;
    if (this.currentToken !== '') {
      const obj = {
        "token": this.fcmToken
      }
      this.unsubscribeForNotifications(obj).subscribe((resp) => {
        this.currentStatus.next(false);
        console.log(resp);
      },
        (err) => {
          this.currentStatus.next(true);
          console.error('Unable to unsubscribe token in spring restfull service.', err);
        });
      this.deleteToken();
    }
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      });
  }

  getCurrentStatus() {
    return this.currentStatus.asObservable();
  }
}
