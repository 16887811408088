export class Constants {
    /**
     * PATHS BLOCK
     */
    public static readonly PATH_EMPTY = '';
    public static readonly PATH_LOGIN = 'login';
    public static readonly PATH_PASSWORD = 'password';
    public static readonly PATH_HOME = '';
    public static readonly PATH_CREATE = 'create';
    public static readonly PATH_PORDER_EDIT = 'edit';
    public static readonly PATH_START = 'start';
    public static readonly PATH_P_ORDER = 'p-order';
    public static readonly PATH_PO_PURCHASE_INTL = 'po';
    public static readonly PATH_USERS = 'users';
    public static readonly PATH_INDUSTRIAL_SHED = 'industrial-shed';
    public static readonly PATH_INDUSTRIAL_SHED_DETAIL = 'detail';
    public static readonly PATH_STRUCTURE = 'structure';
    public static readonly PATH_ASSOCIATION = 'association';
    public static readonly PATH_WAREHOUSE = 'warehouse';
    public static readonly PATH_TAG = 'tag';
    public static readonly PATH_REPORT = 'report';
    public static readonly PATH_ABOUT = 'about';
    public static readonly PATH_ERROR = 'error';
    public static readonly PATH_STEPPER = 'stepper';
    public static readonly PATH_PO_EDIT = 'edit';
    public static readonly PATH_PROFILE = 'profile';
    public static readonly PATH_IMPORT = 'import';
    public static readonly PATH_DASHBOARD = 'dashboard';
    public static readonly PATH_ROUTE_IMPORT_BODYTABS_EDIT = 'bodytabs';
    public static readonly PATH_ROUTE_IMPORT_SHIPMENT_CREATE = 'shipment';
    public static readonly PATH_ROUTE_IMPORT_SHIPMENT_EDIT = 'shipment/edit';

    /**
     * Roles Definitions
     */
    public static readonly ROLE_DASHBOARD = 'Dashboard';
    public static readonly ROLE_PLANNING = 'Planning';
    public static readonly ROLE_GENERATE_PO = 'GeneratePO';
    public static readonly ROLE_IMPORT = 'Import';
    public static readonly ROLE_CREATE_USER = 'CreateUser';
    public static readonly DEVICE_DESKTOP = 'desktop';
    public static readonly DEVICE_MOBILE = 'mobile';
    static PATH_IMPORT_BODYTABS_EDIT: any;
    public readonly PATH_P_ORDER = 'p-order';
    public readonly PATH_P_ORDER_EDIT = 'p-order/edit';
    public readonly PATH_P_ORDER_STEPPER_EDIT = 'p-order/stepper';
    public readonly PATH_PO_PURCHASE_EDIT = 'po/edit';
    public readonly PATH_ROUTE_IMPORT_SHIPMENT_CREATE = 'import/shipment';
    public readonly PATH_ROUTE_IMPORT_SHIPMENT_EDIT = 'import/shipment/edit';
    public readonly PATH_IMPORT_BODYTABS_EDIT = 'import/bodytabs';

    public readonly PATH_HOME = '';

    public readonly ROLE_DASHBOARD = 'Dashboard';
    public readonly ROLE_PLANNING = 'Planning';
    public readonly ROLE_GENERATE_PO = 'GeneratePO';
    public readonly ROLE_IMPORT = 'Import';
    public readonly ROLE_CREATE_USER = 'CreateUser';

    /**
     * Devices Definitions
     */

    public readonly DEVICE_DESKTOP = 'desktop';
    public readonly DEVICE_MOBILE = 'mobile';

    /**
     * Dates Definitions
     */
     public readonly DATE_MIN = new Date(1990, 0, 1);
     public readonly DATE_MAX = new Date(2050, 0, 1);
     public readonly DATE_FORMAT = 'MM-dd-yyyy';

    /**
     * Buttons Labels
     */
     public readonly BTN_SAVE = 'Salvar';
     public readonly BTN_EDIT = 'Editar';
     public readonly BTN_ADD = 'Adicionar';
     public readonly BTN_ADD_MODEL = 'Adicionar Modelo';
     public readonly BTN_ADD_PART = 'Adicionar Peça';
     public readonly BTN_UPDATE = 'Atualizar';
     public readonly BTN_CANCEL = 'Cancelar';
     public readonly BTN_CAD = 'Cadastrar';

     /**
      * Errors Messages
      */
      public readonly MSG_ERROR = 'Algo deu errado';
      public readonly MSG_ERROR_LOADING = 'Erro ao carregar';
      public readonly MSG_ERROR_LOADING_LIST = 'Erro ao carregar lista';
      public readonly MSG_ERROR_REQUIRED = 'Campo obrigatório';
      public readonly MSG_ERROR_ONLY_NUMBERS = 'Somente números';
      public readonly MSG_ERROR_ONLY_NUMBERS_EG = 'Ex.: 2020';
      public readonly MSG_ERROR_DATA_CRON = 'Verificar campos de data';
      public readonly MSG_ERROR_DATA_OUTOFRANGE = 'Fora do limite de datas';
      public readonly MSG_ERROR_MIN_LENGTH_2 = 'Mínimo 2 caracteres';
      public readonly MSG_ERROR_MIN_LENGTH_4 = 'Mínimo 4 caracteres';
      public readonly MSG_ERROR_MIN_LENGTH_6 = 'Mínimo 6 dígitos';
      public readonly MSG_ERROR_ONLYONE = 'Mais de um modelo encontrado';
      public readonly MSG_ERROR_NOTFOUND = 'Não encontrado';
      public readonly MSG_ERROR_NOT_ZERO = 'Não pode ser zero';
      public readonly MSG_ERROR_NOTFOUND_BIKE_MODELS_ITEMS = 'Não há modelos adicionados';
      public readonly MSG_ERROR_NOTFOUND_PART_ITEMS = 'Não há peças adicionadas';
      public readonly MSG_ERROR_CREATE_PO_REVIEW = 'Erro ao criar uma nova versão da PO';
      public readonly MSG_ERROR_UPDATE_PO_REVIEW = 'Erro ao atualizar a PO';
      public readonly MSG_ERROR_DATA_MIN_MAX = 'Data Inválida';
      public readonly MSG_ERROR_MAX_LENGTH_10 = 'Máximo 10 caracteres';
      public readonly MSG_ERROR_MAX_LENGTH_12 = 'Máximo 12 caracteres';
      public readonly MSG_ERROR_MAX_LENGTH_40 = 'Máximo 40 caracteres';
      public readonly MSG_ERROR_INVALID = 'Campo inválido';
      public readonly MSG_ERROR_EMAIL_INVALID = 'Email inválido';
      public readonly MSG_PASSWORD_WRONG = 'Senhas não coincidem';
      public readonly MSG_ERROR_MIN_VALUE_0 = 'Valor mínimo é 0';
      public readonly MSG_ERROR_MAX_VALUE_999999999 = 'Valor máximo é 1000000000';

      /**
       * Success Messages
       */
      public readonly MSG_SUCESS_FOUND_BIKE_MODELS = 'Modelos encontrados';

      /**
       * TOAST CONFIGS AND MESSAGES
       *
       */
      public readonly TOAST_MSG_SUCESS_INSERT_ITEM = 'Item adicionado com sucesso';
      public readonly TOAST_MSG_SUCESS_UPDATE_ITEM = 'Item atualizado com sucesso';
      public readonly TOAST_MSG_SUCESS_UPDATE_PART_BIKE_ITEM = 'Lista atualizada';
      public readonly TOAST_MSG_SUCESS_DELETE_ITEM = 'Item removido com sucesso';
      public readonly TOAST_MSG_ERROR_INSERT_ITEM = 'Erro ao adicionar item';
      public readonly TOAST_MSG_ERROR_UPDATE_ITEM = 'Erro ao atualizar item';
      public readonly TOAST_MSG_ERROR_DELETE_ITEM = 'Erro ao remover item';
      public readonly TOAST_MSG_ERROR_UPDATE_PART_BIKE_ITEM = 'Erro ao atualizar lista';

      public readonly TOAST_MSG_SUCESS_INSERT_ORDERPLANNING = 'Planejamento salvo com sucesso';
      public readonly TOAST_MSG_ERROR_INSERT_ORDERPLANNING = 'Erro ao salvar o planejamento';
      public readonly TOAST_MSG_SUCESS_UPDATE_ORDERPLANNING = 'Planejamento atualizado com sucesso';
      public readonly TOAST_MSG_ERROR_UPDATE_ORDERPLANNING = 'Erro ao atualizar o planejamento';
      public readonly TOAST_MSG_SUCESS_FINISH_ORDERPLANNING = 'Planejamento finalizado com sucesso';
      public readonly TOAST_MSG_ERROR_FINISH_ORDERPLANNING = 'Erro ao finalizar o planejamento';

      public readonly TOAST_MSG_ERROR_GET_BIKE_MODELS = 'Erro ao carregar lista de modelos';
      public readonly TOAST_MSG_ERROR_GET_BIKE_PARTS = 'Erro ao carregar lista de peças';
      public readonly TOAST_MSG_ERROR_GET_LIST_ORDER_PLANNING = 'Erro ao carregar lista de Planejamentos';

      public readonly TOAST_MSG_ERROR_GET_LIST_PI = 'Erro ao carregar lista de PI\'s';
      public readonly TOAST_MSG_SUCESS_INSERT_PI = 'PI salva com sucesso';
      public readonly TOAST_MSG_SUCESS_UPDATE_PI = 'PI atualizada com sucesso';
      public readonly TOAST_MSG_ERROR_INSERT_PI = 'Erro ao salvar PI';
      public readonly TOAST_MSG_ERROR_UPDATE_PI = 'Erro ao atualizar PI';
      public readonly TOAST_MSG_ERROR_FINISH_PI = 'Erro ao finalizar PI\'s';
      public readonly TOAST_MSG_SUCESS_FINISH_PI = 'PI\'s finalizadas com sucesso.';
      public readonly TOAST_MSG_ERROR_GET_LIST_GOODS = 'Erro ao carregar lista de mercadorias';

      public readonly TOAST_MSG_ERROR_UPLOADING_FILE = 'Erro ao enviar arquivo';
      public readonly TOAST_MSG_ERROR_DOWNLOADING_FILE = 'Erro ao baixar arquivo';

      public readonly TOAST_MSG_SUCCESS_INSERT_USER = 'Usuário salvo com sucesso';
      public readonly TOAST_MSG_ERROR_INSERT_USER = 'Erro ao salvar o usuário';
      public readonly TOAST_MSG_SUCCESS_UPDATE_USER = 'Usuário atualizado com sucesso';
      public readonly TOAST_MSG_ERROR_UPDATE_USER = 'Erro ao atualizar o usuário';
      public readonly TOAST_MSG_SUCCESS_DELETE_USER = 'Usuário removido com sucesso';
      public readonly TOAST_MSG_ERROR_DELETE_USER = 'Erro ao remover usuário';
      public readonly TOAST_MSG_ERROR_GET_LIST_USER = 'Erro ao carregar lista de usuários';

      public readonly TOAST_MSG_SUCCESS_RESET_PASSWORD = 'Senha padrão restaurada para o usuário de matrícula';
      public readonly TOAST_MSG_ERROR_RESET_PASSWORD = 'Erro ao restaurar senha padrão';
      public readonly TOAST_MSG_SUCCESS_RECOVER_PASSWORD = 'Verifique seu e-mail ou entre em contato com administrador do sistema';
      public readonly TOAST_MSG_ERROR_RECOVER_PASSWORD = 'Verifique seu e-mail ou entre em contato com administrador do sistema';
      public readonly TOAST_MSG_NEED_CHANGE_PASSWORD = 'Alteração de senha obrigatória!';
      public readonly TOAST_MSG_NEED_CHANGE_PASSWORD_DO_LOGIN = 'Faça login novamente para prosseguir';
      public readonly TOAST_MSG_ERROR_CHANGE_PASSWORD = 'Erro ao alterar senha';


      public readonly TOAST_MSG_SUCESS_INSERT_PACKAGE = 'Pacote de Importação criado com sucesso';
      public readonly TOAST_MSG_SUCESS_DELETE_PACKAGE = 'Pacote de Importação excluído com sucesso';
      public readonly TOAST_MSG_SUCESS_UPDATE_PACKAGE = 'Pacote de Importação atualizado com sucesso';
      public readonly TOAST_MSG_ERROR_INSERT_PACKAGE = 'Erro ao criar Pacote de Importação';
      public readonly TOAST_MSG_ERROR_QTD_INSERT_PACKAGE = 'Verificar';
      public readonly TOAST_MSG_ERROR_UPDATE_PACKAGE = 'Erro ao atualizar Pacote de Importação';
      public readonly TOAST_MSG_ERROR_DELETE_PACKAGE = 'Erro ao excluir Pacote de Importação';

      public readonly TOAST_MSG_ERROR_GET_LIST_SHIPMENT = 'Erro ao carregar lista de Embarques';
      public readonly TOAST_MSG_SUCESS_INSERT_SHIPMENT = 'Embarque criado com sucesso!';
      public readonly TOAST_MSG_SUCESS_UPDATE_SHIPMENT = 'Embarque atualizado com sucesso!';
      public readonly TOAST_MSG_SUCESS_FINISH_SHIPMENT = 'Embarque finalizado com sucesso!';
      public readonly TOAST_MSG_ERROR_FINISH_SHIPMENT = 'Erro ao finalizar Embarque';
      public readonly TOAST_MSG_ERROR_INSERT_SHIPMENT = 'Erro ao salvar o novo Embarque';
      public readonly TOAST_MSG_ERROR_UPDATE_SHIPMENT = 'Erro ao atualizar Embarque';
      public readonly TOAST_MSG_ERROR_DOWLOADING_PO_REVIEW = 'Erro ao baixar po';

      public readonly TOAST_MSG_ERROR_UPDATE_PAYMENT = 'Erro ao atualizar o pagamento';
      public readonly TOAST_MSG_SUCCESS_SAVE_PAYMENT = 'Pagamento atualizado com sucesso';


      public readonly  DICTIONARY = {
        'orderPlanning.already.exists': 'Já existe um planejamento com este número do pedido',
        'bike.model.already.exist.in.order': 'Modelo já inserido no planejamento',
        CREATION: 'Em Andamento',
        VERIFICATION: 'Em Andamento',
        FINISHED: 'Finalizado',
        'code.already.exists.in.that.po.review': 'Código já cadastrado em outra PI',
        Administrator: 'Administrador',
        Manager: 'Gerente',
        Staff: 'Funcionário',
        'cannot.associate.pi.wiht.a.not.finished.po.review': 'PI não pode ser associada a uma PO não finalizada.',
        'pi.is.finished.and.cannot.be.deleted': 'PI está finalizada e não pode ser deletada.',
        'cannot.finish.pis.of.a.not.finish.poreview': 'Não é possível finalizar uma PI de uma PO não finalizada.',
        'there.is.no.poitems.in.that.poreview': 'Não existem itens ativos nesta PO.',
        'there.is.no.pis.in that.review': 'Não existem PIs associadas a esta PO.',
        'cannot.finish.pi.without.edt': 'Não é possível finalizar PI sem ETD.',
        'cannot.finish.pi.without.confirmation.date': 'Não é possível finalizar PI sem data de confirmação.',
        'cannot.finish.pi.without.poitems': 'Não é possível finalizar uma PI sem mercadorias.',
        'a.poitem.not.belong.to.that.poreview': 'Uma ou mais mercadorias não pertencem a esta PO.',
        'cannot.finish.pi.without.payments': 'Não é possível finalizar uma PI sem forma de pagamento.',
        'poreview.total.value.and.pis.total.value.not.matching': 'Soma dos valores das PIs não coincide com o valor total da PO.',
        'po.item.already.exists.in.another.pi.in.that.po.review': 'Mercadoria já cadastrada em outra PI.',
        'pi.is.finished.and.cannot.be.updated': 'PI está finalizada e não pode ser editada.',
        'cannot.finish.pi.without.delivery.port': 'Não é possível finalizar PI sem porto de origem.',
        'cannot.finish.pi.without.destination.port': 'Não é possível finalizar uma  PI sem porto de destino.',
        'email.already.exists' : 'Email já cadastrado.',
        'registerId.already.exists' : 'Matrícula já cadastrada.',
        'user.or.password.mismatch': 'Erro ao fazer login',
        'user.root.cannot.be.updated' : 'Não é possível atualizar usuário administrador',
        'user.cannot.disable.your.own.account' : 'Não é possível desabilitar a própria conta',
        'import.package.code.already.exists' : 'O código do pacote já existe',
        'incorrect.package.item.quantity' : 'Erro na quantidade de um ou mais itens',
        'cannot.finish.pis.of.a.deprecated.po.review': 'Não é possível finalizar PI\'s de uma PO cuja revisão não é a atual.',
        'cannot.update.pi.of.a.deprecated.po.review': 'Não é possível atualizar PI\'s de uma PO cuja revisão não é a atual.',
        'type.a.password.different.of.default': 'Nova senha deve ser diferente da senha padrão.',
        // tslint:disable-next-line: max-line-length
        'for.security.reasons.new.password.must.not.be.equals.register.id': 'Por razões de segurança, a nova senha não deve ser igual a matrícula.',
        'delete.constraint.violation' : 'Não é possível deletar item finalizado.',
        'access.denied' : 'Acesso negado.',
        'some.import.package.is.deprecated.and.shipment.cannot.be.updated' : 'Existem pacotes de importação depreciados',
        'shipment.identifier.already.exists' : 'O código de embarque já existe',
        'must.exist.at.least.one.import.package.to.finalize' : 'Não é possível finalizar embarque sem pacote de importação',
        'shipment.already.finalized' : 'Embarque já finalizado',
        'shipment.finished.cannot.be.deleted' : 'Embarque concluído não pode ser deletado',
        'package.import.with.shipment.cannot.be.updated' : 'Pacote de importação está associado a um embarque e não pode ser editado',
        'currency.consistency.error' : 'Peças com inconsistência de moeda',
        'error.generating.payment.pdf.report' : 'Erro ao gerar relatório',
        'error.alter.price.po.items.consistency' :'Erro ao gerar relatório por inconsistẽncia de moeda',
        'part.already.exists.in.order' : 'Peça já inserida no plenejamento'
      };


      public readonly TOAST_SUCESS = {
        timeOut: 5000,
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-full-width',
      };
      public readonly TOAST_ERRO = {
        timeOut: 5000,
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-full-width',
      };

      // currency
      public static readonly DOLLAR_CURRENCY = 'U$';
      public static readonly FORMAT_DECIMAL = 'en-US';  // // change this value to set up deciaml format {'en-US'= 1,000.00 / 'de-DE'= 1.000,00} 
    }
