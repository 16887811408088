import { Injectable, OnInit } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserAdminService } from '../home/user-admin/service/user-admin.service';
import { User, UserJWT } from '@shared/models';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '@shared/constants';
import { DeviceDetectorService } from 'ngx-device-detector';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  jwt = new JwtHelperService();
  redirectUrl;
  userLogged: User;
  currentUser: User;
  userLoggedForceLogin = false;
  userJWT: UserJWT;
  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private userAdminService: UserAdminService,
    private constants: Constants,
    private deviceService: DeviceDetectorService
  ) {
    this.userAdminService.currentUserLogged.subscribe(_currentUser => {
      this.setUserLogged(_currentUser);
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    let expectedRoleArray: string[] = route.data.expectedRole;
    let expectedDeviceArray: string[] = route.data.expectedDevice;

    if (this.checkLogin(route) && this.checkDevice(expectedDeviceArray) && this.checkRole(expectedRoleArray) ) {
      return true;
    }
    return false;
  }

  private checkLogin(route: ActivatedRouteSnapshot): boolean {
    // tslint:disable-next-line: no-string-literal
    const redirectUrl = route['_routerState']['url'];
    // Get Params to Redirect
    this.route.queryParams
      .subscribe(
        (success) => {
          this.redirectUrl = success.redirectUrl;
        }
      );

    if (this.authService.isLoggedIn()
      && !this.jwt.isTokenExpired(this.authService.getJwtToken())
      && !this.userLoggedForceLogin
    ) {
      return true;
    }
    this.router.navigateByUrl(
      this.router.createUrlTree(
        ['/login'], {
        queryParams: {
          redirectUrl
        }
      }
      )
    );
    return false;
  }

  private checkRole(expectedRoleArray: string[]): boolean {
    let flag = false;

    const userJWT = this.jwt.decodeToken(this.authService.getJwtToken());

    if (userJWT) {

      const roles: string[] = (userJWT.roles as string).split('|');

      roles.forEach(item => {
        if (expectedRoleArray.indexOf(item) !== -1) {
          flag = true;
        }
      });

      if (flag) {
        return true;
      }
      if (!this.deviceService.isMobile()) {
        if (roles.indexOf(Constants.ROLE_DASHBOARD) !== -1) {
          this.router.navigate([Constants.PATH_DASHBOARD]);
        } else if (roles.indexOf(Constants.ROLE_PLANNING) !== -1) {
          this.router.navigate([Constants.PATH_P_ORDER]);
        } else if (roles.indexOf(Constants.ROLE_GENERATE_PO) !== -1) {
          this.router.navigate([Constants.PATH_PO_PURCHASE_INTL]);
        } else if (roles.indexOf(Constants.ROLE_IMPORT) !== -1) {
          this.router.navigate([Constants.PATH_IMPORT]);
        } else if (roles.indexOf(Constants.ROLE_CREATE_USER) !== -1) {
          this.router.navigate([Constants.PATH_USERS]);
        }
      }else {
        if (roles.indexOf(Constants.ROLE_DASHBOARD) !== -1) {
          this.router.navigate([Constants.PATH_DASHBOARD]);
        }else{
          this.router.navigate([Constants.PATH_PROFILE])
        }
      }
    }
    return false;
  }

  private checkDevice(expectedDeviceArray: string[]) {
    const isMobile = this.deviceService.isMobile();
    if (expectedDeviceArray.indexOf(this.constants.DEVICE_DESKTOP) !== -1 && !isMobile) {
      return true;
    } else if (expectedDeviceArray.indexOf(this.constants.DEVICE_MOBILE) !== -1 && isMobile) {
      return true;
    } else {
      this.router.navigate([Constants.PATH_DASHBOARD]);
      return false;
    }
  }

  private setUserLogged(user: User) {
    if (user.name) {
      this.userLogged = user;
      this.userLoggedForceLogin = user.forceNewPassword;
      if (user.forceNewPassword) {
        this.router.navigate(['/login']);
      }
    } else {
      // this.userLoggedForceLogin = true; // Caso não haja usuário, forcar o FALSE no CanActivate
      if (this.authService.getJwtToken()
        && !this.jwt.isTokenExpired(this.authService.getJwtToken())
        // && !user.name
        // && !user.forceNewPassword
      ) {
        this.userJWT = this.jwt.decodeToken(this.authService.getJwtToken());
        this.userAdminService.setUserLogged(parseInt(this.userJWT.user_id));
      }
    }
  }
}
