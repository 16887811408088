export class User {
    name: string;
    email: string;
    birthday: string;
    enabled?: boolean;
    forceNewPassword?: boolean;
    register_id: string;
    role: string[];
    id: number;
    dashboardNotification?: boolean;
}
