import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Constants } from '@shared/constants';
import { AuthGuard } from './auth/';



const approutes: Routes = [
  {
    path: Constants.PATH_LOGIN,
    loadChildren: './login/login.module#LoginModule'
  },
  {
    path: Constants.PATH_HOME,
    loadChildren: './home/home.module#HomeModule',
    canActivate: [AuthGuard],
    data: {
      expectedRole: [
        Constants.ROLE_DASHBOARD,
        Constants.ROLE_PLANNING,
        Constants.ROLE_GENERATE_PO,
        Constants.ROLE_IMPORT,
        Constants.ROLE_CREATE_USER
      ],
      expectedDevice: [Constants.DEVICE_DESKTOP, Constants.DEVICE_MOBILE]
    }
  },
  {
    path: '**',
    redirectTo: Constants.PATH_HOME
  }

];

@NgModule({
  imports: [RouterModule.forRoot(approutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
