export class ItemPartOrdemPlanning {
    id: number;
    partID: string;
    orderPlanningID: number;
    quantity: number;
    partDescription?: string;
    observation?: string;
    includeInPurchase?: boolean;
    unitMeasurement?: string;
    supplierCode?: string;
    supplierName?: string;
    price?: number;
    supplierPartDesc?: string;
    supplierPartCode?: string;
    partTotalPrice?: number;
    //currency refactoring
    alterPriceFlag?: boolean;
    alterPrice?: number;
    alterPriceSimb?: string;
    partTotalAlterPrice?: number;
}
