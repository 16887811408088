import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileAttach } from '@shared/models';
import { config } from '@shared/config/config';


@Injectable({
  providedIn: 'root'
})
export class FileAttachService {

  constructor(
    private http: HttpClient
    ) { }
  
  public upload(fileToUpload: File): Observable<FileAttach> {
    const input = new FormData();
    input.append('file', fileToUpload, fileToUpload.name)
    return this.http.post<any>(`${config.apiUrl}/rest/file/`, input);
  }

  public download(fileID: number){
    return this.http.get(`${config.apiUrl}/rest/file/`+ fileID, {
      responseType: 'blob' as 'json',
    })
  }

  public downloadPoPDF(poReviewId: number) {
    return this.http.get(`${config.apiUrl}/rest/poReview/exportToPdf/` +  poReviewId, {
      responseType: 'blob' as 'json',
      observe: 'response'
      });
  }

  public downloadPoXLS(poReviewId: number) {
    return this.http.get(`${config.apiUrl}/rest/poReview/exportToXlsx/` +  poReviewId, {
      responseType: 'blob' as 'json',
      observe: 'response'
      });
  }

  public downloadPaymentPDF(ids: string) {
    return this.http.get(`${config.apiUrl}/rest/payment/exportToPdf/` +  ids, {
      responseType: 'blob' as 'json',
      observe: 'response'
      });
  }

  public downloadPaymentXLS(ids: string) {
    return this.http.get(`${config.apiUrl}/rest/payment/exportToXlsx/` +  ids, {
      responseType: 'blob' as 'json',
      observe: 'response'
      });
  }

  public handleFile(res: any, fileName: string) {
    const file = new Blob([res], {
      type: res.type
    });

    //IE 11
    if(window.navigator && window.navigator.msSaveOrOpenBlob){
      window.navigator.msSaveOrOpenBlob(file)
      return;
    }

    const blob = window.URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = blob;
    link.download = fileName;

    link.dispatchEvent(new MouseEvent('click',{
      bubbles: true,
      cancelable: true,
      view: window
    }));

    setTimeout(()=>{ // firefox
      window.URL.revokeObjectURL(blob);
      link.remove();
    }, 100);
  }
}
