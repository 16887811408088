import { ImportPackage, FileAttach } from '.';

export class Shipment {
    id: number;
    identifier: string;
    arrivalPortDate?: string;
    bl?: string;
    cntr?: string;
    color?: string;
    contract?: string;
    exchange?: boolean;
    exchangeDate?: string;
    exchangeRate?: number;
    freightRate?: number;
    importDocument?: string;
    importPackages?: ImportPackage[];
    fileAttaches: FileAttach[];
    incomingInvoice?: string; // NF
    incomingInvoiceDate?: string; // NF DATE
    invoice?: string; // INVOICE
    observation?: string;
    receivingDate?: string;
    shipName?: string;
    shipmentDate?: string;
    deliveryPort?: string;
    destinationPort?: string;
    finished: boolean;
    allImportPackageActual: boolean;
    statusFilterSearch?: string;
    observationDocImport?: string;
}
