import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { MatDialogRef, MatSlideToggle } from '@angular/material';
import { MessagingService } from '@shared/services/messaging.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-push-notification-dialog',
  templateUrl: './push-notification-dialog.component.html',
  styleUrls: ['./push-notification-dialog.component.css']
})
export class PushNotificationDialogComponent implements OnInit, OnDestroy {
  @ViewChild('notifyToggle', { static: false }) notifyToggle: MatSlideToggle;
  public pushNotifyStatus: boolean = false;
  alive = true;
  public loading = false;
  constructor(public dialogRef: MatDialogRef<PushNotificationDialogComponent>,
    private messagingService: MessagingService,
    private zone: NgZone) { }

  ngOnInit() {
    this.messagingService.getCurrentStatus().pipe(takeWhile(() => this.alive)).subscribe((resp) => {
      this.pushNotifyStatus = resp;
      this.zone.run(() => {
        this.setLoadingFalse();
      });
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  setLoadingFalse(){
    this.loading = false;
  }

  public notificationStatusChange(checked: boolean): void {
    if (checked && !this.pushNotifyStatus) {
      this.loading = true;
      this.messagingService.requestPermission();
    } else if (!checked && this.pushNotifyStatus) {
      this.loading = true;
      this.messagingService.unsubscribe();

    }
    this.notifyToggle.checked = this.pushNotifyStatus;
  }

}
