export class Payment{
    id: number;
    value: number;
    percentage: number;
    dueDate: string;
    observation: string;
    piID: number;
    //currency refactoring 
    hasAlterValue?: boolean;
    alterValue?: number;
    alterValueSimb?: string;
}