import { Pi } from './pi';

export class PiFinishedToImport extends Pi {
    orderNumber: string;
    orderID: number;
    orderLine: string;
    poSupplierName: string;
    poSupplierCode: string;
    poID: number;
    poReviewVersion: number;
    actualFinished: boolean;
    fullAllocated: boolean;
    statusFilterSearch?: string;
    etdFilterSearch?: string;
}
