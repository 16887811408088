export class PartOrderPlanning {
    partID: string;
    partDescription: string;
    type: string;
    unitMeasurement: string;
    group: string;
    supplierCode: string;
    supplierName: string;
    price: number;
    supplierPartDesc: string;
    supplierPartCode: string;
    //currency refactoring
    alterPriceFlag?: boolean;
    alterPrice?: number;
    alterPriceSimb?: string;
}

// {
//     "partID": "O0060005",
//     "partDescription": "CAMARA KENDA ARO 29 BUTIL 48MM TODAS              ",
//     "type": "MI",
//     "unitMeasurement": "UN",
//     "group": "O006",
//     "supplierCode": "0019",
//     "supplierName": "KENDA RUBBER IND. CO., LTD.             ",
//     "price": 5.76,
//     "supplierPartDesc": "29*1.9/2.3 F/V 48MM...TUBES",
//     "supplierPartCode": "PARTNUMBERO00600050019"
//   }
