import { PoItem, FileAttach, Payment } from '@shared/models/';

export class Pi {
    id: number;
    code: string;
    value?: number;
    deliveryPort?: string;
    destinationPort?: string;
    finished: boolean;
    etd?: string;
    eta?: string;
    confirmationDate?: string;
    poReviewID: number;
    poItems?: PoItem[];
    fileAttaches?: FileAttach[];
    payments?: Payment[];
    //currency refactoring 
    hasAlterValue?: boolean;
    alterValue?: number;
    alterValueSimb?: string;
}
