import { CookieModule } from 'ngx-cookie';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MaterialModule } from '@shared/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SnackbarService } from '@shared/services';
import { AuthService,
         AuthGuard,
         TokenInterceptor } from './auth/';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Constants } from '@shared/constants';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA, MatPaginatorIntl, MAT_DATE_LOCALE, MatSortModule } from '@angular/material';
import { getPaginatorIntl } from './home/_templateParts/paginator/paginator-intl';
import { ConfirmationDialogComponent } from './shared/components/confirmation-dialog/confirmation-dialog.component';
import { LoadingViewAppComponent } from './shared/components/loading-view-app/loading-view-app.component';
import { UserAdminService } from './home/user-admin/service/user-admin.service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { PushNotificationDialogComponent } from './shared/components/push-notification-dialog/push-notification-dialog.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationDialogComponent,
    LoadingViewAppComponent,
    PushNotificationDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    MatSortModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true,
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-bottom-full-width',
      maxOpened: 1
    }),
    CookieModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production }),
  ],
  exports: [
    MaterialModule,
    FlexLayoutModule
  ],
  providers: [
    AuthService,
    // AuthGuard,
    Constants,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    SnackbarService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmationDialogComponent,
    LoadingViewAppComponent,
    PushNotificationDialogComponent
  ]
})
export class AppModule { }
