export class PoItem {
    partID: string;
    partDescription: string;
    unitMeasurement: string;
    price: number;
    totalQuantity: number;
    supplierPartDesc: string;
    supplierPartCode: string;
    partTotalPrice: number;
    includeInPurchase: boolean;
    poReviewID?: number;
    id: number;
    //currency refactoring
    alterPriceFlag?: boolean;
    alterPrice?: number;
    alterPriceSimb?: string;
    partTotalAlterPrice?: number;
}
