import { PoItem } from '@shared/models';
export class PoReview {
    finished: boolean;
    id: number;
    poID?: number;
    poItems: PoItem[];
    review: number;
    supplierETD?: Date;
    actual?: boolean;
}
