import { ItemBikeOrdemPlanning } from './';

export class OrderPlanning {
    id:	number;
    orderNumber: string;
    line?: number;
    etaManaus?: string;
    etdAsia?: string;
    orderDate?: string;
    productionDate?:	string;
    putDate?:	string;
    creationDate?:	string;
    items?: ItemBikeOrdemPlanning[];
    currentStage?: string;
    generatePOStageFinished?: boolean;
    importStageFinished?: boolean;

}
