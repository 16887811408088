export class UserJWT {
    user_id: string;
    sid: string;
    name: string;
    username: string;
    roles: string;
    pass_signature: string;
    iss: string;
    iat: number;
    exp: number;
}
