import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  msg;

  constructor(
    private snackBar: MatSnackBar
  ) { }

  snackBarDefautl(message: string, action: string, duration: number, vposition) {
    return this.msg = this.snackBar.open(message, action, {
       duration: (duration),
       horizontalPosition: 'right',
       verticalPosition: vposition,
       announcementMessage: 'anucio',
    });
    // this.msg.afterDismissed().subscribe(() => {
    //   console.log('The snack-bar was dismissed!!');
    //   this.route.navigate['/login'];
    // });
    // this.msg.onAction().subscribe(() => {
    //   console.log('The snack-bar action was triggered!');
    // });
    // this.msg.dismiss();
  }
}
