import { PackageItem } from '@shared/models';

export class ImportPackage {
    code: string;
    id: number;
    piID: number;
    packageItems: PackageItem[];
    orderNumber?: string;
    orderID?: number;
    poID?: number;
    shipmentID?: number;
    shipmentName?: string;
    orderLine?: string;
    poSupplierName?: string;
    poSupplierCode?: string;
    piActualFinished?: boolean;
    statusFilterSearch?: string;
    etdFilterSearch?: string;
    deliveryPortFilterSearch?: string;
    destinationPortFilterSearch?: string;
    piCodeFilterSearch?: string;
}
