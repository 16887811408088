import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { of, Observable, Subject } from 'rxjs';
import { catchError, mapTo, tap , map} from 'rxjs/operators';
import { config } from '@shared/config';
import { Tokens, User, UserJWT } from '@shared/models/';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { sha256 } from 'js-sha256';
import { UserAdminService } from '../home/user-admin/service/user-admin.service';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private userLogged: User;
  private userJWT: UserJWT;
  public headers: HttpHeaders;
  jwt = new JwtHelperService();
  constructor(
    private http: HttpClient,
    // private sha256: sha256
    private userService: UserAdminService,
    ) {
    this.headers = new HttpHeaders();
    this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    this.headers.append('Accept', 'application/json');
    this.headers.append('content-type', 'application/json');
    this.headers.append('responseType', 'arraybuffer');
    this.headers.append('content-type', 'application/octet-stream');
    this.headers.append('headers', 'getHeaders');
  }

  // loginOLD(user: { username: string, password: string }): Observable<boolean> {
  //   return this.http.post<any>(`${config.apiUrl}/auth/login`, user)
  //     .pipe(
  //       tap(tokens => {
  //         this.doLoginUser(user.username, tokens)
  //         //console.log('Token Authorization: '+tokens.headers.get('Authorization'));
  //       }),
  //       mapTo(true),
  //       catchError(error => {
  //         console.log('CatchError Login:' + error.error);
  //         return of(false);
  //       })
  //     );
  // }


  login(user: { username: string, password: string }): Observable<HttpResponse<Object>> {
    this.doLogoutUser();
    const passUser = {username: user.username, password: sha256(user.password) };
    return this.http.post<HttpResponse<Object>>(`${config.apiUrl}/auth/login`, passUser, { headers: this.headers,  observe: 'response' })
    .pipe(
      tap((response: HttpResponse<any>) => {
          const tk = {
            jwt: response.headers.get('Authorization'),
            refreshToken: response.headers.get('Authorization'),
          };
        // console.log('response a: ', response.headers.get('Authorization'));
          this.doLoginUser(user.username, tk);
        // this.return(response)
      },
      ),
      // tap((response: HttpResponse<any>) => { this.return(response);}),
    );
  }

  // return(response: HttpResponse<any>): Observable<boolean> | boolean {
  //   let ok = false;
  //   const userJWT: UserJWT = this.jwt.decodeToken(response.headers.get('Authorization'));
  //   // console.log(userJWT)
  //   this.userService.getUserById( parseInt(userJWT.user_id) ).subscribe(
  //     (user) => {
  //       // console.log('no return', user)
  //       this.userService.setUserLogged(user);
  //       ok = true;
  //     },
  //     (error) => {
  //       // TODO: Tratar erro de get de usuário por ID
  //       ok = false;
  //     }
  //     );
  //   return ok;
  // }

  logout() {
    this.doLogoutUser();
    // console.log('logout');
    // return this.http.post<any>(`${config.apiUrl}/logout`, {refreshToken: this.getRefreshToken()}).pipe(
    //   tap(() => this.doLogoutUser()),
    //   mapTo(true),
    //   catchError(error => {
    //     alert(error.error);
    //     return of(false);
    //   }));
  }

  isLoggedIn() {
    return this.getJwtToken();
  }

  refreshToken() {
    return this.http.post<any>(`${config.apiUrl}/refresh`, {
      refreshToken: this.getRefreshToken()
    }).pipe(tap((tokens: Tokens) => {
      this.storeJwtToken(tokens.jwt);
    }));
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private doLoginUser(username: string, tokens: Tokens) {
    // console.log('doLoginUser');
    // console.log('doLogin username: ' + username);
    // console.log('doLogin tokens: ' + tokens);
    this.storeTokens(tokens);
    // this.setUserLogged(this.jwt.decodeToken(tokens.jwt));
  }

  private doLogoutUser() {
    this.removeTokens();
    // this.userService.setUserLogged();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }

  private setUserLogged(userJWT: UserJWT) {
    console.log('set User no auth servi', userJWT);
    this.userService.setUserLogged(parseInt(userJWT.user_id));
    // this.userService.getUserById( parseInt(userJWT.user_id) ).subscribe(
    //   (user) => {
    //   },
    //   (error) => {
    //     // TODO: Tratar erro de get de usuário por ID
    //   }
    // );
  }
}
