import { Component } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { FirebaseApp } from '@angular/fire';
import { MessagingService } from '@shared/services/messaging.service';
import '@firebase/messaging';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'front-end';

  constructor(private messagingService: MessagingService,
    private sw: SwPush,
    private firebase: FirebaseApp) {

    //Firebase integration with ngsw
    try {
      this.sw.messages.subscribe(console.log);
      const messaging = this.firebase.messaging();
      this.messagingService.receiveMessage();
    } catch (err) {
      console.error("Firebase Messaging not supported by the browser")
    }


    // navigator.serviceWorker.getRegistration().then(registration => {
    //   if (!!registration && registration.active && registration.active.state && registration.active.state === 'activated') {
    //     messaging.useServiceWorker(registration);
    //     //this.messagingService.requestPermission();
    //     this.messagingService.receiveMessage();
    //   } else {
    //     console.warn('No active service worker found, not able to get firebase messaging');
    //   }

    // });

  }
}
