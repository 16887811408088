export class BikeModelPartItem {
    uso: number;
    partID: string;
    partDescription: string;
    unitMeasurement: string;
    supplierCode: string;
    supplierName: string;
    price: number;
    supplierPartDesc: string;
    supplierPartCode: string;
    partQuantity: number;
    partTotalPrice: number;
    includeInPurchase: boolean;
    bikeModelItemID: number;
    id: number;
    //currency refactoring
    alterPriceFlag?: boolean;
    alterPrice?: number;
    alterPriceSimb?: string;
    partTotalAlterPrice?: number;
}
