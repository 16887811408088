import { config } from '@shared/config/config';
import { User } from '@shared/models/user.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { isNull, isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class UserAdminService {
  
  user: User = new User();
  currentUser: User;
  
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
  ) {

  }
  private userLogged = new BehaviorSubject<User>(this.user);
  currentUserLogged = this.userLogged.asObservable();

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${config.apiUrl}/rest/user/`);
  }

  saveUser(user: User): Observable<User> {
    return this.http.post<User>(`${config.apiUrl}/rest/user/`, user);
  }

  updateUser(user: User): Observable<User> {
    return this.http.put<User>(`${config.apiUrl}/rest/user/`, user);
  }

  getUserById(id: number): Observable<User> {
    return this.http.get<User>(`${config.apiUrl}/rest/user/${id}`);
  }

  async getUserByIdSync(id: number){
    const result = await this.http.get<User>(`${config.apiUrl}/rest/user/${id}`).toPromise();
    return result;
  }

  deleteUser(id) {
    return this.http.delete(`${config.apiUrl}/rest/user/${id}`);
  }

  resetDefaultPassword(user: User): Observable<User> {
    return this.http.put<User>(`${config.apiUrl}/rest/user/reset/`, user);
  }

/**
 *
 * @param idUser Set User Logged by Id User
 */
  setUserLogged(idUser?: number, user?: User) {

    if (idUser > 0 && isNullOrUndefined(user)) {
      this.getUserByIdSync(idUser).then(
        (user) => {
          this._setUserLogged(user);
          this.currentUser = user;
        },
        (error) => {
        console.log(error);
      // TODO: Erro ao carregar usuário
      });
    } else if (!isNullOrUndefined(user)) {
      this._setUserLogged(user);
    } else {
      this._setUserLogged(this.user);
    }
  }

  private _setUserLogged(user: User) {
    return this.userLogged.next(user);
  }

  getUserLogged(): Observable<User> {
    return this.userLogged.asObservable();
  }
}
